.ripple-container {
  position: absolute;
  inset: 0;
}

.ripple-item {
  position: absolute;
  background-color: var(--ripple-color);
  animation: ripple 600ms linear forwards;
  display: inline-block;
  border-radius: 50%;
  transform: scale(0);
  pointer-events: none;
}

@keyframes ripple {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
