.link-bold {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 700;
}

.link {
  text-decoration: none;
  color: var(--primary-color);
}

.card {
  border-radius: var(--border-radius-md);
  background-color: var(--creame-color);
  padding: calc(var(--spacing) * 1.5) calc(var(--spacing) * 2);
}

.job-id {
  border: 1px solid #bbcdc9 !important;
  border-radius: var(--spacing);
  padding: var(--spacing);
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  gap: calc(var(--spacing) * 0.5);
}

.main-list {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  &-item {
    display: flex;
    align-items: center;
    gap: var(--spacing);
    padding: calc(var(--spacing) * 2) 0;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid var(--button-borders-color);
    }
  }
}
