.Drawer {
  &-overlay {
    height: 100vh;
    width: 100vw;
    background-color: var(--modal-overlay-color);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.2s;
    z-index: 100;
    &-transparent {
      .Drawer-overlay {
        background-color: transparent !important;
      }
    }
  }
  &-content {
    position: absolute;
    z-index: 200;
    display: inline-flex;
  }
  &-left {
    &-enter,
    &-appear {
      .Drawer-overlay {
        opacity: 0;
        visibility: hidden;
      }
      .Drawer-content {
        transform: translate3d(-100%, 0, 0);
        height: 100vh;
      }
    }
    &-appear-active,
    &-enter-active,
    &-enter-done {
      .Drawer-overlay {
        opacity: 1;
        visibility: visible;
        transition: 300ms;
      }
      .Drawer-content {
        transform: translate3d(0, 0, 0);
        transition: 0.3s;
        height: 100vh;
      }
    }
    &-exit {
      .Drawer-overlay {
        opacity: 1;
        visibility: visible;
      }
      .Drawer-content {
        transform: translate3d(0, 0, 0);
        transition: 0.3s;
        height: 100vh;
      }
    }
    &-exit-active {
      .Drawer-overlay {
        opacity: 0;
        visibility: hidden;
        transition: 300ms;
      }

      .Drawer-content {
        transform: translate3d(-100%, 0, 0);
        height: 100vh;
      }
    }
  }
  &-right {
  }
  &-top {
  }
  &-bottom {
    &-enter,
    &-appear {
      .Drawer-overlay {
        opacity: 0;
        visibility: hidden;
      }
      .Drawer-content {
        transform: translate3d(0, 100%, 0);
        bottom: 0;
        width: 100%;
      }
    }
    &-appear-active,
    &-enter-active,
    &-enter-done {
      .Drawer-overlay {
        opacity: 1;
        visibility: visible;
        transition: 300ms;
      }
      .Drawer-content {
        transform: translate3d(0, 0, 0);
        transition: 0.3s;
        bottom: 0;
        width: 100%;
      }
    }
    &-exit {
      .Drawer-overlay {
        opacity: 1;
        visibility: visible;
      }
      .Drawer-content {
        transform: translate3d(0, 0, 0);
        transition: 0.3s;
      }
    }
    &-exit-active {
      .Drawer-overlay {
        opacity: 0;
        visibility: hidden;
        transition: 300ms;
      }

      .Drawer-content {
        transform: translate3d(0, 100%, 0);
        bottom: 0;
        width: 100%;
      }
    }
  }
}
