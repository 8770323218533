.accordion {
  overflow: hidden;
  &-summary {
    display: flex;
    align-items: center;
    padding: calc(var(--spacing) * 2) calc(var(--spacing) * 1.5);
    border: 1px solid var(--button-borders-color);
    border-radius: var(--border-radius-sm);
    cursor: pointer;
    & > * {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-starticon {
      margin-right: calc(var(--spacing) * 2);
    }
    &-children {
    }

    &-expandicon {
      margin-left: auto;
    }
  }
  &-body-container {
    padding: 0 var(--spacing);
    max-height: 0;
    transition: all 250ms ease-in-out;
    overflow: hidden;
    box-sizing: border-box;
  }
}

.icon-expanded-enter {
  transform: rotate(0deg);
}
.icon-expanded-enter-active {
  transform: rotate(180deg);
  transition: all 250ms ease-in-out;
}
.icon-expanded-enter-done {
  transform: rotate(180deg);
}
.icon-expanded-exit {
  transform: rotate(180deg);
}
.icon-expanded-exit-active {
  transform: rotate(0deg);
  transition: all 250ms ease-in-out;
}

.body-expanded-enter {
  max-height: 0;
}
.body-expanded-enter-active {
  max-height: 100vh;
  transition: all 250ms ease-in-out;
}
.body-expanded-enter-done {
  max-height: 100vh;
}
.body-expanded-exit {
  max-height: 100vh;
}
.body-expanded-exit-active {
  max-height: 0;
  transition: all 250ms ease-in-out;
}
