.OpenSmsPage {
  width: 100vw;
  height: 100vh;
  padding: calc(var(--spacing) * 2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: calc(var(--spacing) * 2);
}
