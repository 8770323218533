.checkbox {
  &-label {
    box-sizing: content-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: var(--spacing);
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    width: 24px;
    height: 24px;

    & input:checked + .checkbox-checkmark {
      background-color: var(--primary-color);
    }
    & input:checked + .checkbox-checkmark::after,
    & input:checked + .checkbox-checkmark::before {
      display: block;
    }
  }
  &-custom-label {
  }
  &-checkmark {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid var(--input-border-color);
    border-radius: calc(var(--spacing) * 0.5);
    transition: 0.1s;
    &::before,
    &::after {
      display: none;
      content: '';
      position: absolute;
      background-color: var(--white-color);
      height: 2px;
    }
    &::before {
      width: 7px;
      transform: rotate(45deg);
      left: 15%;
      top: 45%;
    }
    &::after {
      top: 38%;
      width: 14px;
      transform: rotate(315deg);
      left: 31%;
    }
  }

  input {
    position: absolute;
    height: 0;
    width: 0;
  }
}
