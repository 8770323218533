.PageLoader {
  height: 100vh;
  width: 100vw;
  position: 'absolute';
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: calc(var(--spacing) * 3);
}
