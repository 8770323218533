.LocationModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 312px;
  max-height: 80vh;
  padding: 32px 20px 24px 20px;
  overflow-y: scroll;
  border-radius: 28px;
  background-color: var(--bg-color);
}

.modal-text {
  margin-top: 10px;
  &__item {
    padding: 0 10px;
    font: var(--font-md);
    font-weight: 400;
    line-height: 20px;
    color: var(--black-color);
  }
}

.modal-buttons {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
