.select-container {
  position: relative;
  width: 100%;
}

.select-dropdown {
  position: absolute;
  z-index: 3;
  left: 0;
  top: 100%;
  width: 100%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 5px;
}
.select-dropdown-item {
  text-align: left;
  padding: 5px;
  padding-left: 15px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
}
