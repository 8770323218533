.JobStatus {
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 8px;
  margin-bottom: 8px;
  svg {
    width: 24px;
    height: 24px;
  }
}
