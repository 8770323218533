.phone-input {
  &-container {
    color: red;
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
  }
  &-field {
    position: static !important;
    height: 42px !important;
    border: 1px solid var(--input-border-color) !important;
    border-radius: var(--border-radius-sm) !important;
    padding: calc(var(--spacing) * 2) !important;
    // max-width: 100% !important;
    // width: 100% !important;
    flex-grow: 1;
    &::placeholder {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #a4a6b3;
    }
    &:focus {
      border-color: var(--input-border-color-active) !important;
      outline: none;
    }
  }
  &-button {
    position: static !important;
    height: 42px !important;
    border: 1px solid var(--input-border-color) !important;
    border-radius: var(--border-radius-sm) !important;
    padding: calc(var(--spacing) * 2) !important;
    background-color: transparent !important;
    &:active {
      border-color: var(--input-border-color-active) !important;
      outline: none;
    }
  }
  &-dropdown {
    left: 0;
    top: 100%;
    border-radius: var(--border-radius-sm) !important;
    width: 100% !important;
    color: var(--text-color);
  }
}
