.ProfilePage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &-content {
    padding: 0 16px;
    flex-grow: 1;
    overflow: auto;
  }
  &-footer {
    border-top: 1px solid var(--input-border-color);
    padding: 16px;
  }

  .avatar-edit {
    position: absolute !important;
    top: 0px;
    right: -5px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: var(--bg-color);
    pointer-events: none;

    svg {
      position: absolute;
      fill: var(--primary-color);
    }
  }
}
