.Message {
  max-width: 100%;
  overflow: hidden;
  padding: 6px 16px;
  &-text {
    word-break: break-word;
    white-space: pre-wrap;
  }
  &-avatar {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: var(--mint-color);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
