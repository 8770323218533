@use 'sass:color';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,700&display=swap');

// Colors
$green-dark: #143a32;
$green-medium: #006944;
// $light-green: #BBCDC9;
$cream: #f3f0ea;
$cream-transparent80: rgba(243, 240, 234, 0.8019607843);
$mint: #cfeae2;
$fire-red: #ec6352;
$gray-light: #dcdedd;
$gray-light-1: #e9eceb;
$gray-medium: #7e8b88;
$black-color: #0a2520;
$white-color: #ffffff;

$primary-color: $green-dark;
$primary-active: color.mix($primary-color, white, 92%);

$secondary-color: $mint;
$error-color: $fire-red;

$button-borders-color: $gray-light-1;
$input-border-color: $gray-light;
$input-border-color-active: $primary-color;
$dialog-overlay: rgba(0, 0, 0, 0.5);

//Border-radius
$border-radius-sm: 12px;
$border-radius-md: 16px;
$border-radius-lg: 28px;

//Shadows

$main-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
$header-drop-shadow: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));
$footer-box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15);

//Transition
$button-transition: 0.1s;

// Font

$font-family: 'DM Sans', sans-serif;

$font-size-sm: 12px;
$line-height-sm: 18px;

$font-size-md: 14px;
$line-height-md: 20px;

$font-size-lg: 18px;
$line-height-lg: 22px;

// Spacing
$spacing: 8px;

//Layout sizes
//Map Layout
$header-map-layout-height: 119px;

//Auth Layout

:root {
  --primary-color: #{$primary-color};
  --primary-active: #{$primary-active};
  --primary-active: #{$primary-active};
  --secondary-color: #{$secondary-color};
  --error-color: #{$error-color};
  --black-color: #{$black-color};
  --white-color: #{$white-color};
  --mint-color: #{$mint};
  --bg-color: #{$white-color};
  --text-color: #{$black-color};
  --text-secondary: #{$gray-medium};
  --green-medium: #{$green-medium};
  --gray-light: #{$gray-light};
  --ripple-color: #{$primary-active};
  --creame-color: #{$cream};
  --cream-transparent80: #{$cream-transparent80};
  --modal-overlay-color: rgba(0, 0, 0, 0.5);

  --font-sm: #{$font-size-sm} / #{$line-height-sm} #{$font-family};
  --font-md: #{$font-size-md} / #{$line-height-md} #{$font-family};
  --font-lg: #{$font-size-lg} / #{$line-height-lg} #{$font-family};

  --button-borders-color: #{$button-borders-color};
  --input-border-color: #{$input-border-color};
  --input-border-color-active: #{$input-border-color-active};
  --dialog-overlay-color: #{$dialog-overlay};

  --border-radius-sm: #{$border-radius-sm};
  --border-radius-md: #{$border-radius-md};
  --border-radius-lg: #{$border-radius-lg};

  --main-box-shadow: #{$main-box-shadow};
  --header-drop-shadow: #{$header-drop-shadow};
  --footer-box-shadow: #{$footer-box-shadow};

  --spacing: #{$spacing};

  --button-transition: #{$button-transition};

  --modal-transition: 0.3s cubic-bezier(0.41, 0.17, 0.36, 1.7);
}
