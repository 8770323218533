.ChatPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &-header {
    box-shadow: var(--main-box-shadow);
  }
  &-content {
    padding-top: 16px;
    flex-grow: 1;
    overflow: auto;
    background-color: var(--creame-color);
  }
  &-footer {
    display: flex;
    gap: var(--spacing);
    padding: calc(var(--spacing) * 1);
    border-top: 1px solid var(--input-border-color);
    width: 100%;
    max-width: 100%;
    box-shadow: var(--footer-box-shadow);
    & > *:not(:last-child) {
      flex-grow: 1;
    }
  }

  &-messages {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing) * 2);
    min-height: 100%;
    padding-bottom: var(--spacing);
  }
}

.MessageList {
  &-date-group {
    position: relative;
  }
  &-date-label-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 10px;
    z-index: 100;
  }
  &-date-label {
    padding: 4px 8px;
    background: var(--cream-transparent80);
    border-radius: 12px;
    text-align: center;
    color: black;
    font-size: 14px;
  }
}
