@import url('./_normalize.scss');
@import url('./variables.scss');
@import url('./utils.scss');
@import url('./shared.scss');

* {
  margin: 0;
  padding: 0;
}

body {
  font: var(--font-md);
  background: var(--bg-color);
  color: var(--text-color);
  height: 100vh;
  button,
  input {
    font: var(--font-md);
  }
}
