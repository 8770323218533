.textarea {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 343px;
    max-width: 100%;
    position: relative;
  }
  &-wrapper {
    position: relative;
    width: 100%;
  }
  &-label {
    font-weight: 700;
    color: var(--black-color);
    margin-bottom: 6px;
  }
  &-field {
    width: 100%;
    height: auto;
    min-height: 42px;
    border: 1px solid var(--input-border-color);
    border-radius: var(--border-radius-sm);
    padding: calc(var(--spacing));
    resize: none;

    &::placeholder {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #a4a6b3;
    }
    &:focus {
      border-color: var(--input-border-color-active);
      outline: none;
    }
    &:focus::placeholder {
      color: transparent;
    }
  }
  &-endAdornment {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__password__wrapper {
    position: relative;
    width: 100%;
  }
  &__password__show {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 4%;
    transition: 0.3s;
    cursor: pointer;
  }
  &-error {
    .textarea-label {
      color: var(--error-color);
    }
    .textarea-field {
      border-color: var(--error-color);
    }
  }
  &-error-message {
    color: var(--error-color);
    font-size: 13px;
  }
  &-fullWidth {
    width: 100%;
  }
}

.code-field {
  width: 56px;
  height: 56px;
  text-align: center;
  font: var(--font-lg) !important;
}

.error {
  border-color: var(--error-color);
  &:focus {
    border-color: var(--error-color);
  }
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
