.file-input-container {
}
.file-input-custom-label {
  font-weight: 700;
  color: var(--black-color);
  margin-bottom: 6px;
}
.file-input-label {
  display: flex;
  align-items: center;
  gap: var(--spacing);
  cursor: pointer;
}

.file-input-icon {
}
.file-input-placeholder {
  color: var(--green-medium);
  font-weight: 700;
}

.file-input-edit {
  margin-left: auto;
}

.file-input-error {
  color: var(--error-color);
}
