.otp-container {
  &-inputs {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
  }
}

.otp-error {
  color: var(--error-color);
  font-weight: 700;
  text-align: center;
  margin-top: var(--spacing);
}
