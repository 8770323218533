.CancelJob {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &-content {
    padding: 16px;
    flex-grow: 1;
    overflow: auto;
  }
  &-footer {
    border-top: 1px solid var(--input-border-color);
    padding: 16px;
  }
}
