.avatar {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  background-color: var(--mint-color);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
  font-weight: 500;
  color: var(--primary-color);
  max-width: 100%;
  max-height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
