.HeaderWithTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 15px;
}

.title {
  font: var(--font-size-lg);
  font-weight: 700;
  color: var(--black-color);
  line-height: 22px;
}

.chat-info {
  position: relative;
  &__counter {
    position: absolute;
    top: -5px;
    right: -11px;
    padding: 0 5px;
    background-color: #ec6352;
    font: var(--font-sm);
    border-radius: 100px;
    color: var(--white-color);
  }
}

.menu {
  background: none;
  border: none;
}
