.ChatListPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &-content {
    padding: 16px;
    flex-grow: 1;
    overflow: auto;
  }
  &-footer {
    border-top: 1px solid var(--input-border-color);
    padding: 16px;
  }
}

.ChatList-list {
  margin-left: calc(var(--spacing) * 2 * -1);
  margin-right: calc(var(--spacing) * 2 * -1);
  padding-top: var(--spacing);
}

.ChatList-item {
  padding: var(--spacing) calc(var(--spacing) * 2);
  border-bottom: 1px solid var(--gray-light);
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: var(--spacing);
  position: relative;
  overflow: hidden;
  --ripple-color: var(--mint-color);
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
  &:first-child {
    border-top: 1px solid #e9eceb;
  }
  &:last-child {
    border: 0;
  }
  &-info {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 6px;
    overflow: hidden;
    &-row {
      display: flex;
      width: 100%;
      align-items: center;
    }
  }
  &-title {
    padding: 0;
    margin: 0;
    flex-grow: 1;
  }
  &-time {
  }
  &-last-msg {
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 1;
    overflow: hidden;
    padding-right: 4px;
  }
  &-unread-count {
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
