.Switcher {
  border-radius: var(--border-radius-md);
  border: 1px solid var(--input-border-color);
  display: inline-flex;
  overflow: hidden;
  cursor: pointer;
  &-state {
    padding: calc(var(--spacing) * 1.2) calc(var(--spacing) * 1.5);
    display: flex;
    align-items: center;
    gap: 5px;
    &:first-child {
      border-right: 1px solid var(--button-borders-color);
    }
    &-on {
      .icon {
        display: none;
      }
    }
    &-off {
      .icon {
        display: block;
      }
      background: var(--mint-color);
    }
  }

  input:disabled + .Switcher-state-off,
  input:disabled + .Switcher-state-off + .Switcher-state-on {
    opacity: 0.5;
  }

  input:checked + .Switcher-state-off {
    .icon {
      display: none;
    }
    background: transparent;
  }
  input:checked + .Switcher-state-off + .Switcher-state-on {
    .icon {
      display: block;
    }
    background: var(--mint-color);
  }
}
