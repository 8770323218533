.JobsMapPage {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.list {
  padding-left: 8px;
  padding-bottom: 15px;
  display: flex;
  width: 100%;
  overflow-x: scroll;
  box-shadow: 0 2px 4px -2px gray;
  position: relative;
  z-index: 2;
  &__item {
    padding: 3px 9px;
    margin-right: 16px;
    border: 1px solid var(--button-borders-color);
    border-radius: 16px;
    list-style: none;
    cursor: pointer;
    &:hover {
      background: var(--mint-color);
      transition: 0.2s;
    }
    &-btn {
      display: flex;
      align-items: center;
      background: none;
      border: none;
    }
    &-count {
      margin-right: 4px;
      font: var(--font-md);
    }
    &-active {
      background: var(--mint-color);
    }
  }
}

.map-wrapper {
  flex-grow: 1;
}

.show-jobs-list {
  position: absolute;
  width: 80%;
  left: 50%;
  bottom: 20px;
  transform: translate3d(-50%, 0, 0);
}
