// Flex
.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
.d-inline-flex {
  display: inline-flex;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.direction-column {
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.space-between {
  justify-content: space-between;
}

.flex-grow-1 {
  flex-grow: 1;
}

.stack {
  display: flex;
  flex-direction: column;
}

.gap-05 {
  gap: calc(var(--spacing) * 0.5);
}
.gap-1 {
  gap: var(--spacing);
}
.gap-2 {
  gap: calc(var(--spacing) * 2);
}
.gap-3 {
  gap: calc(var(--spacing) * 3);
}

//Width

.w-100 {
  width: 100%;
}

//Fonts
.fw-700 {
  font-weight: 700 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.text-center {
  text-align: center;
}
.text-secondary {
  color: var(--text-secondary);
}

.font-sm {
  font: var(--font-sm);
}
.font-md {
  font: var(--font-md);
}
.font-lg {
  font: var(--font-lg);
}

//Spacing

// 1 spacing

.m-x-auto {
  margin: 0 auto;
}

.mt-1 {
  margin-top: var(--spacing);
}
.mb-1 {
  margin-bottom: var(--spacing);
}
.mr-1 {
  margin-right: var(--spacing);
}
.ml-1 {
  margin-left: var(--spacing);
}

.mt-2 {
  margin-top: calc(var(--spacing) * 2);
}
.mb-2 {
  margin-bottom: calc(var(--spacing) * 2);
}
.mr-2 {
  margin-right: calc(var(--spacing) * 2);
}
.ml-2 {
  margin-left: calc(var(--spacing) * 2);
}

.mt-3 {
  margin-top: calc(var(--spacing) * 3);
}
.mb-3 {
  margin-bottom: calc(var(--spacing) * 3);
}
.mr-3 {
  margin-right: calc(var(--spacing) * 3);
}
.ml-3 {
  margin-left: calc(var(--spacing) * 3);
}

//4 spacing

.mt-4 {
  margin-top: calc(var(--spacing) * 4);
}
.mb-4 {
  margin-bottom: calc(var(--spacing) * 4);
}
.mr-4 {
  margin-right: calc(var(--spacing) * 4);
}
.ml-4 {
  margin-left: calc(var(--spacing) * 4);
}

//Padding
.p-1 {
  padding: var(--spacing);
}
.p-2 {
  padding: calc(var(--spacing) * 2);
}
.p-3 {
  padding: calc(var(--spacing) * 3);
}
.p-4 {
  padding: calc(var(--spacing) * 4);
}

.pt-1 {
  padding-top: var(--spacing);
}
.pb-1 {
  padding-bottom: var(--spacing);
}
.pr-1 {
  padding-right: var(--spacing);
}
.pl-1 {
  padding-left: var(--spacing);
}

.pt-2 {
  padding-top: calc(var(--spacing) * 2);
}
.pb-2 {
  padding-bottom: calc(var(--spacing) * 2);
}
.pr-2 {
  padding-right: calc(var(--spacing) * 2);
}
.pl-2 {
  padding-left: calc(var(--spacing) * 2);
}

.pt-3 {
  padding-top: calc(var(--spacing) * 3);
}
.pb-3 {
  padding-bottom: calc(var(--spacing) * 3);
}
.pr-3 {
  padding-right: calc(var(--spacing) * 3);
}
.pl-3 {
  padding-left: calc(var(--spacing) * 3);
}

//Transform
.rotate-270 {
  transform: rotate(270deg);
}

//Other
.pointer {
  cursor: pointer;
}

.shadow {
  box-shadow: var(--footer-box-shadow);
}

//Position

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

//Colors
.primary-color {
  color: var(--primary-color);
}

//Borders

.border-bottom {
  border-bottom: 1px solid #e6e0e9;
}
