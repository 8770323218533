@use 'sass:color';
@mixin active-styles() {
  &:not(.disabled):not(:disabled) {
    &:active,
    &.active,
    &:focus {
      @content;
    }
    @media (hover: hover) {
      &:hover {
        @content;
      }
    }
  }
}

@mixin no-ripple-styles() {
  body.animation-level-0 & {
    &:not(.disabled):not(:disabled) {
      &:active {
        @content;
      }
    }
  }
}

.btn {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-sm);
  padding: 0;
  transition: var(--button-transition);
  font-weight: 700;
  text-decoration: none;
  &:disabled {
    opacity: 0.7;
  }
  --ripple-color: rgba(0, 0, 0, 0.15);
  &--round {
    border-radius: 50%;
  }

  &--small {
    padding: 8px;
  }
  &--medium {
    padding: 12px;
  }
  &--large {
    padding: 16px;
  }

  &--contained.btn--primary {
    background: var(--primary-color);
    color: var(--white-color);
    @include active-styles {
      background: var(--primary-active);
    }
    --ripple-color: rgba(255, 255, 255, 0.15);
  }

  &--outlined.btn--primary {
    background: transparent;
    color: var(--primary-color);
    border: 1px solid var(--button-borders-color);
    @include active-styles {
      background: var(--mint-color);
    }
  }

  &--outlined.btn--error {
    background: transparent;
    color: var(--error-color);
    border: 1px solid var(--button-borders-color);
  }

  &-text.btn--error {
    background: transparent;
    color: var(--error-color);
    border: none;
  }
  &-text.btn--primary {
    background: transparent;
    color: var(--primary-color);
    border: none;
  }

  &--fullwidth {
    width: 100%;
  }
}

.Button-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: inherit;
}
