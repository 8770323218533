.JobsListByFilter {
  width: 100%;

  max-height: 400px;
  overflow-y: auto;
  background-color: var(--bg-color);
  border-top-left-radius: var(--border-radius-lg);
  border-top-right-radius: var(--border-radius-lg);
  padding: 30px 10px;
  box-shadow: var(--footer-box-shadow);
}
