.ActivityLogItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  font-weight: 500;
  padding-top: calc(var(--spacing) * 3);
  padding-bottom: calc(var(--spacing) * 3);
  &:not(:last-child) {
    border-bottom: 1px solid #e6e0e9;
  }
}
