.Mainmenu {
  width: 90vw;
  background-color: var(--bg-color);
  border-top-right-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);
  padding: calc(var(--spacing) * 4) calc(var(--spacing) * 3.5) calc(var(--spacing) * 2);
  display: flex;
  flex-direction: column;

  &-footer {
    margin-top: auto;
  }
}
