.Jobtypes-form {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &-content {
    padding: 0 16px;

    padding-top: 60px;
    flex-grow: 1;
    overflow: auto;
  }
  &-title {
    max-width: 270px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
