.JobHistoryPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &-content {
    padding: 16px 8px;
    flex-grow: 1;
    overflow: auto;
  }
}

.history-job-wrapper {
  border: 1px solid var(--input-border-color);
  padding: calc(var(--spacing) * 1.5);
  border-radius: var(--border-radius-md);
}
