.JobDetiailsPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &-content {
    padding: 0 16px;
    padding-top: 16px;
    flex-grow: 1;
    overflow: auto;
  }
  &-footer {
    display: flex;
    gap: var(--spacing);
    padding: calc(var(--spacing) * 2);
    border-top: 1px solid var(--input-border-color);
    width: 100%;
    max-width: 100%;

    & > *:not(:last-child) {
      flex-grow: 1;
    }
  }
}

.tabs {
  display: flex;
  justify-content: center;
  gap: calc(var(--spacing) * 2);
  font-weight: 500;
  border-bottom: 1px solid #e6e0e9;
}

.tab {
  padding: calc(var(--spacing) * 1.5) calc(var(--spacing) * 2);
  position: relative;
  &.active {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 4px;
      background-color: var(--green-medium);
      bottom: 0;
      left: 0;
      border-top-left-radius: 100px;
      border-top-right-radius: 100px;
    }
  }
}
