.jobs-map {
  width: 100%;
  height: 100%;
}

.page-wrapper {
  height: 100vh;
  width: 100vw;
}

.searching-location {
  width: 100%;
  background-color: var(--bg-color);
  border-top-left-radius: var(--border-radius-sm);
  border-top-right-radius: var(--border-radius-sm);
  padding: calc(var(--spacing) * 3);
  padding-top: calc(var(--spacing) * 4);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}
