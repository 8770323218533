.select {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // margin-bottom: 24px;
    width: 343px;
    max-width: 100%;
    position: relative;
  }
  &-wrapper {
    position: relative;
    width: 100%;
  }
  &-label {
    font-weight: 700;
    color: var(--black-color);
    margin-bottom: 6px;
  }
  &-field {
    width: 100%;
    height: 42px;
    border: 1px solid var(--input-border-color);
    border-radius: var(--border-radius-sm);
    padding: calc(var(--spacing) * 0.5);
    &::placeholder {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #a4a6b3;
    }
    &:focus {
      border-color: var(--input-border-color-active);
      outline: none;
    }
    &:focus::placeholder {
      color: transparent;
    }
  }
  &-endAdornment {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__password__wrapper {
    position: relative;
    width: 100%;
  }
  &__password__show {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 4%;
    transition: 0.3s;
    cursor: pointer;
  }
  &-error {
    .input-label {
      color: var(--error-color);
    }
    .input-field {
      border-color: var(--error-color);
    }
  }
  &-error-message {
    color: var(--error-color);
    font-size: 13px;
  }
  &-fullWidth {
    width: 100%;
  }
}
